import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const MemberImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      kunal_s: file(relativePath: { eq: "members/kunal-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 448) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      saquib_s: file(relativePath: { eq: "members/saquib-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 448) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      raman_s: file(relativePath: { eq: "members/raman-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 448) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aakash_s: file(relativePath: { eq: "members/aakash-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 448) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yogesh_s: file(relativePath: { eq: "members/yogesh-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 448) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      puja_s: file(relativePath: { eq: "members/puja-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinesh_s: file(relativePath: { eq: "members/dinesh-small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 448) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jim_s: file(relativePath: { eq: "members/jim-small.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      subhash_s: file(relativePath: { eq: "members/subhash-small.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kunal_l: file(relativePath: { eq: "members/kunal-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      saquib_l: file(relativePath: { eq: "members/saquib-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      raman_l: file(relativePath: { eq: "members/raman-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aakash_l: file(relativePath: { eq: "members/aakash-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yogesh_l: file(relativePath: { eq: "members/yogesh-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      puja_l: file(relativePath: { eq: "members/puja-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dinesh_l: file(relativePath: { eq: "members/dinesh-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jim_l: file(relativePath: { eq: "members/jim-large.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1122) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      subhash_l: file(relativePath: { eq: "members/subhash-large.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 948) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default MemberImg
