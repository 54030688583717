import React, { Component } from "react"
import "../scss/about.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MemberImg from "../components/member-img"
import {
  getMembers,
  setDefaultHeaderTheme,
  navbarUpdate,
  getAchievements,
} from "../utils/common-util"

class About extends Component {
  constructor(props) {
    super(props)

    this.memberDet = React.createRef()
    this.$achievements = React.createRef()

    this.achievements = getAchievements()

    this.maxAchWidth = 0
    this.fullAchWidth = 0
    this.achIconWidth = 0

    this.setMaxAchWidth = elem => {
      if (elem) {
        const w =
          elem.lastElementChild.clientWidth +
          elem.lastElementChild.clientWidth * 0.13
        if (w > this.maxAchWidth) {
          this.maxAchWidth = w
        }
        if (!this.fullAchWidth) {
          this.fullAchWidth = elem.clientWidth / 2
        }
        if (!this.achIconWidth) {
          this.achIconWidth = elem.firstElementChild.clientWidth
        }
      }
    }

    this.state = {
      selectedMemberIdx: null,
    }
  }

  componentDidMount() {
    setDefaultHeaderTheme(true)
    navbarUpdate("nav-about")

    if (window.innerWidth < 769) {
      this.maxAchWidth += this.achIconWidth + 15
      this.maxAchWidth /= 2

      const lp = this.fullAchWidth - this.maxAchWidth + 12
      this.$achievements.current
        .querySelectorAll(".column.achievement")
        .forEach(ach => {
          ach.style.paddingLeft = lp.toFixed(1) + "px"
        })
    }
  }

  getAchievementsFrom(start, end) {
    let achs = []
    for (let i = start; i <= end; i++) {
      const ach = this.achievements[i]
      const $ach = (
        <div
          key={ach.id}
          className="column achievement"
          ref={this.setMaxAchWidth}
        >
          <span className={"ach-icon ach-" + ach.icon}></span>
          <span className="ach-info">
            <span className="achievement-text">{ach.text}</span>
            <span className="num">{ach.num}</span>
          </span>
        </div>
      )
      achs.push($ach)
    }
    return achs
  }

  render() {
    const toggleMemberView = idx => {
      this.setState({
        selectedMemberIdx: idx,
      })
      if (idx != null) {
        setTimeout(() => {
          this.refs.memberDet.scrollTo(idx * this.refs.memberDet.clientWidth, 0)
        }, 100)
      }
    }

    const changeMemberDet = n => {
      const index = this.state.selectedMemberIdx + n
      this.refs.memberDet.scrollTo(index * this.refs.memberDet.clientWidth, 0)
      this.setState({ selectedMemberIdx: index })
    }

    const members = getMembers()

    return (
      <Layout>
        <SEO title="About" />
        <div className="abt-top-content iww-full-height iww-container">
          <h1 className="top-heading">At the Forefront of Innovation</h1>
          <div className="top-desc">
            We believe in transformation and keeping up with the latest
            technological developments.
          </div>
          <div className="numbers-for-achievements" ref={this.$achievements}>
            <div className="columns">{this.getAchievementsFrom(0, 3)}</div>
            <div className="columns">{this.getAchievementsFrom(4, 7)}</div>
          </div>
        </div>
        <div className="intro-content iww-container">
          <div className="columns">
            <div className="column">
              <span className="intro-desc-left">
                <span>
                  <i className="fas fa-quote-left"></i>
                </span>
                Founded in 2014, we have evolved from an enterprise mobility
                model to a holistic computer vision based solution that
                determines actionable intelligence from images, videos, and data
                captured live.
                <span>
                  <i className="fas fa-quote-right"></i>
                </span>
              </span>
            </div>
            <div className="column">
              <div className="intro-heading">
                Integration Wizards is readily exploring the cross-hairs of AI,
                Computer Vision, IoT, and Enterprise Mobility
              </div>
              <div className="intro-desc">
                Having collaborations with renowned organizations such as
                Microsoft and Nvidia, we are confident in our approach towards
                our services and solutions.
                <br />
                <br />
                Worked across pharma, oil and gas, retail and consumer goods, to
                name a few of the verticals and believe our automation solutions
                can integrate almost any unit of the digital universe.
              </div>
            </div>
          </div>
        </div>
        <div className="our-team-content">
          <div className="ot-heading">Meet Our Team</div>
          <div className="members">
            {members.map((mem, i) => {
              return (
                <div
                  className="member-tile"
                  key={mem.id}
                  onClick={toggleMemberView.bind(this, i)}
                >
                  <MemberImg
                    className="member-img"
                    name={mem.img}
                    alt={mem.alt}
                  ></MemberImg>
                  <div className="member-name">{mem.name}</div>
                  <div className="member-pos">{mem.pos}</div>
                </div>
              )
            })}
          </div>
          <div
            className={
              "modal" +
              (this.state.selectedMemberIdx != null ? " is-active" : "")
            }
          >
            <div
              className="modal-background"
              onClick={toggleMemberView.bind(this, null)}
            ></div>
            {this.state.selectedMemberIdx != null ? (
              <div className="modal-content">
                <div className="members-left-pan">
                  <div className="pan-actions">
                    <div>
                      {this.state.selectedMemberIdx > 0 ? (
                        <div
                          onClick={changeMemberDet.bind(this, -1)}
                          className="prev-mem faa-parent animated-hover"
                        >
                          <span className="icon">
                            <i className="fas fa-long-arrow-alt-left faa-horizontal"></i>
                          </span>
                          <span>
                            <span className="pre-mem-name">
                              {members[this.state.selectedMemberIdx - 1].name}
                            </span>
                            <span className="pre-mem-pos">
                              {members[this.state.selectedMemberIdx - 1].pos}
                            </span>
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {this.state.selectedMemberIdx < members.length - 1 ? (
                        <div
                          onClick={changeMemberDet.bind(this, 1)}
                          className="next-mem faa-parent animated-hover"
                        >
                          <span>
                            <span className="nxt-mem-name">
                              {members[this.state.selectedMemberIdx + 1].name}
                            </span>
                            <span className="nxt-mem-pos">
                              {members[this.state.selectedMemberIdx + 1].pos}
                            </span>
                          </span>
                          <span className="icon">
                            <i className="fas fa-long-arrow-alt-right faa-horizontal"></i>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div ref="memberDet" className="member-details-wrap">
                    <div
                      className="member-details"
                      style={{
                        width: members.length * 100 + "%",
                      }}
                    >
                      {members.map(mem => {
                        return (
                          <div className="member-det" key={mem.id + "-det"}>
                            <div className="member-info">
                              <div className="name-pos">
                                <div className="mem-name">{mem.name}</div>
                                <div className="mem-pos">{mem.pos}</div>
                              </div>
                              <div className="social-links">
                                <OutboundLink
                                  href={mem.linkedin}
                                  target="_blank"
                                  className="msm-icon linkedin"
                                ></OutboundLink>
                              </div>
                            </div>
                            <div className="about-member">{mem.about}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="members-img-pan">
                  <MemberImg
                    className="member-img"
                    name={members[this.state.selectedMemberIdx].expImg}
                    alt={members[this.state.selectedMemberIdx].alt}
                  ></MemberImg>
                </div>
              </div>
            ) : null}
            <button
              className="modal-close is-large"
              onClick={toggleMemberView.bind(this, null)}
              aria-label="close"
            ></button>
          </div>
        </div>
      </Layout>
    )
  }
}

export default About
